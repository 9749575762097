// Load Styles
import '../scss/main-crolow.scss';

// Load Bootstrap init
import { initBootstrap } from "./bootstrap.js";
import 'jquery';
import 'slick-carousel';
//import './utilities/CrolowForms.js';
import { Tooltip, Toast, Popover } from 'bootstrap';


// Loading bootstrap with optional features
//initBootstrap({
//  tooltip: true,
//  popover: true,
//  toasts: true,
//});

// Your app code
console.log(`Hello ${process.env.HELLO}`);
